export const languages = {
  tr: {
    datePickerlang: "tr",
    headerLeft: [
      {
        url: "/kiralik-villa",
        title: "Kiralık Villa Fiyatları | Uygun Kiralık Villa Seçenekleri 2024",
        text: "Kiralık Villa",
      },
      {
        url: "/2024-villalari",
        title: "2024 Villaları",
        text: "2024 Villaları",
      },
      {
        url: "/bolgeler",
        title: "Bölgeler",
        text: "Bölgeler",
      },
    ],
    reservations: {
      url: "/rezervasyonlarim",
      title: "Rezervasyonlarım",
      ariaLabel: "Kullanıcı Girişi",
    },
    blogPage: {
      url: "blog-kesfet",
    },
    searchResultSlug: "arama-sonuclari",
    menu: "Menü",
    menuUpper: "MENÜ",
    close: "Kapat",
    closeUpper: "KAPAT",
    favourites: "Favoriler",
    searchPlaceholder: "Villa Ara",
    noResult: "Sonuç Bulunamadı",
    searchMinLength: "Arama yapmak için minumum 3 karakter giriniz",
    searchBoxTitle: "%20 Şimdi, Kalanını tatilde ödeme fırsatı!",
    forYou: "Size Özel",
    mobileSliderCaption1: "Havuzlu Kiralık Villalar",
    mobileSliderCaption1_balayi: "Havuzlu Balayı Villaları",
    mobileSliderCaption2: "Balayı Villaları",
    mobileSliderCaption3: "Muhafazakar Villalar",
    mobileSliderCaption3_balayi: "Muhafazakar Balayı Villaları",
    destination: "Bölge",
    villaOptions: "Villa Seçenekleri",
    checkInOutDate: "Giriş - Çıkış Tarihi",
    customer: "Misafir",
    searchUpper: "ARA",
    select: "Seçiniz",
    selected: "seçildi",
    adult: "Yetişkin",
    child: "Çocuk",
    informationBanner1Title: "%100 Para İade Güvencesi",
    informationBanner1SubTitle: "& Ücretsiz İptal",
    informationBanner1ButtonText: "Detaylı bilgi edin",
    documentNo: "Belge No",
    informationBanner2Title:
      "Villa Villam TÜRSAB üyesi bir seyahat Acentasıdır",
    informationBanner2Title_balayi:
      "Balayı Villası Kirala TÜRSAB Seyahat Acentasıdır",
    informationBanner2ButtonText: "Doğrulama için tıklayın",
    informationBanner3Title: "Kredi Kartına 12 aya varan taksit imkanı",
    informationBanner3ButtonText: "Detaylı bilgi edin",
    productDestinationsContainerTitle: "Kiralama Bölgeleri",
    productDestinationsContainerCaption: "Hizmet verdiğimiz kiralama bölgeleri",
    productCategoriesTitle: "Villa tiplerine gözatın",
    all: "Tümü",
    showAll: "Tümünü Görüntüle",
    allRentalVillas: "Tüm Kiralık Villalar",
    nightly: "Gecelik",
    viewDetailUpper: "DETAYLI İNCELE",
    nightUpper: "GECE",
    person: "Kişi",
    bedRoom: "Yatak Odası",
    bathRoom: "Banyo",
    villaItemPrice: "Başlayan Fiyatlarla",
    villaItemPriceNightly: "gecelik fiyat",
    lastMinuteTitle: "Son Dakika {Fırsatları}",
    homeReviewLeftTitleSmall: "Yardıma mı ihtiyacın var?",
    homeReviewLeftTitle: "Villa Bulma Sihirbazı",
    homeReviewLeftText:
      "Seçmekte zorlanıyor musunuz? Formu doldurun, sizin için en uygun evleri listeleyip sunalım.",
    reviews: "Misafır Yorumları",
    allReviews: "Tüm Yorumlar",
    shortTermsTitle: "Kısa Süreli Kiralık Villalar",
    nights: "Gece",
    faqVideoTitle: "Nasıl Kiralarım ?",
    faqVideoText: "VİDEO İZLE",
    footerBannerTitle: "{Size Özel} havuzlu villada tatilini başlat.",
    footerBannerCaption:
      "Türkiye’nin mutluluğa açılan tatil kapısından dilediğin villayı kolayca kirala !",
    footerPaymentLinkButtonText:
      "Websitemiz Kredi Kartlı <br class='lg:hidden' /> Alışverişlerde 256 Bit SSL <br /> Sertifikası ile Korunmaktadır",
    pay: "GÜVENLİ ÖDE",
    copyRight: "2023 @ Tüm Hakkı Saklıdır. İzinsiz İçerik Kullanılamaz.",
    wpSupport: "WhatsApp Destek",
    wpMessage: "{Villa} Hakkında Bilgi almak istiyorum.",
    calendarUnavailable: "Dolu",
    calendarOption: "Opsiyonda",
    calendarLastMinute: "Son Dakika Fırsatı",
    datepickerCheckIn: "Giriş",
    datepickerCheckOut: "Çıkış Tarihi",
    warning: "Uyarı!",
    reservationUnavailableMessage:
      "Görüntülediğiniz  villa rezervasyon alınımına kapalıdır. Dilerseniz diğer villalarımızı inceleyebilirsiniz.",
    childAge: "6-12 Yaş",
    infant: "Bebek",
    infantAge: "0-5 Yaş",
    back: "Geri",
    reservationSubmitButtonText: "ÜCRETSİZ REZERVASYON TALEBİ GÖNDER",
    nameSurname: "Ad Soyad",
    email: "Email",
    phone: "Telefon",
    sendBtnText: "Gönder",
    reservationSummary: "Rezervasyon Özeti",
    accommodationAmount: "Konaklama Tutarı ({night} Gece) ",
    remainingAmount: "Kalan (Villaya girişte ödenecek)",
    cleaningFee: "Temizlik Ücreti",
    totalPrice: "Toplam Tutar",
    depositPrice: "Ön Ödeme",
    watchVideo: "Videoyu İzle",
    showPhotos: "Fotoğrafları Gör",
    favourite: "Favori",
    share: "Paylaş",
    shareUpper: "PAYLAŞ",
    morePhotos: "Fotoğraf Daha",
    availabilityCalendar: "Müsaitlik Takvimi",
    sidebarReservationTitle: "Rezervasyon Yap",
    sidebarReservationSubtitle:
      "Şuan bu villayı <span class='text-orange'>{count} Kişi</span> inceliyor",
    addFavourite: "Favoriye Ekle",
    removeFavourite: "Favoriden Çıkar",
    shareViaWhatsapp: "Whatsapp ile Paylaş",
    shareViaFacebook: "Facebook ile Paylaş",
    shareViaTwitter: "Twitter ile Paylaş",
    shareViaMail: "Mail ile Paylaş",
    copyLink: "Bağlantıyı Kopyala",
    propertiesTitle: "Öne Çıkan Özellikler",
    pricesTitle: "Dönemlik Fiyatlar",
    inludedPrice: "Fiyata Dahil Olanlar",
    notIncludedPrice: "Fiyata Dahil Olmayanlar",
    information: "Bilgilendirme",
    cancellationTerms: "İptal Şartları",
    damageDeposit: "Hasar Depozito",
    checkinTime: "Giriş Saati (En Erken)",
    checkoutTime: "Çıkış Saati (En Geç)",
    discover: "Keşfet",
    poolDimensions: "Havuz Ölçüleri",
    childPool: "Çocuk Havuzu",
    indoorPool: "Kapalı Havuz",
    distancesTitle: "Ulaşım Bilgisi",
    showOnMap: "Haritada Göster",
    locationInformation: "Konum Bilgisi",
    goToLocation: "Konuma git",
    detailSimilarVillasTitle: "Benzer Villalara Gözat",
    favouritesRightTitle: "Favori Listesi",
    favouritesRightNotFound:
      "Favorilere eklenen herhangi bir villa bulunamadı.",
    FavouriteItemDetailBtnText: "Görüntüle",
    FavouriteItemRemoveBtnText: "Kaldır",
    MenubarHomeText: "Anasayfa",
    MenubarSearchText: "Arama Yap",
    MenubarButtonFilterText: "Filtrele",
    MenubarButtonReservationText: "Rezervasyon Yap",
    AllVillas: "Tüm Villalar",
    notDates: "Giriş/Çıkış tarihlerinizi belirtiniz.",
    reservationTitle: "<strong>{baslik}</strong> için Rezervasyon yapıyorsunuz",
    reservationSection1Title: "Kişisel Bilgilerinizi Girin",
    reservationFormNamePlaceholder: "Adınız Soyadınız",
    reservationFormEmailPlaceholder: "E-Posta Adresiniz",
    reservationFormPhonePlaceholder: "Telefon",
    reservationFormPhonePlaceholder2: "Telefon",
    reservationSection2Title: "Onay Sonrası Ödeme Şeklinizi Seçin",
    reservationPaymentAll: "Tamamı",
    paymentMethod1Title: "Havale ile Öde",
    paymentMethod1Description:
      "Ödeme tutarını Havale veya EFT ile yapmak istiyorum.",
    paymentMethod2Title: "Kredi Kartı ile Öde",
    paymentMethod2Description:
      "Ödemenizi kredi kartı ile tek çekim veya 12 aya kadar taksit ile yapabilirsiniz.",
    reservationSection3Title: "Villaya girişte dikkat edilmesi gerekenler",
    reservationDamageDeposit: "Hasar Depozito",
    reservationDamageDepositDescription:
      "Villada herhangi bir hasar olmaması halinde çıkışta iade edilecektir.",
    reservationCheckinCheckoutTime: "Giriş ve Çıkış Saatleri",
    reservationCheckinTime: "Giriş En Erken",
    reservationCheckoutTime: "Çıkış En Geç",
    contractText: "{contractName} okudum kabul ediyorum.",
    contract1: "Kiralama Sözleşmesini",
    contract2: "KVKK Aydınlatma Metnini",
    reservationSendValidationError:
      "Lütfen tüm alanların geçerli olduğundan emin olun.",
    reservationSendBtnText: "REZERVASYON TALEBİ GÖNDER",
    reservationSuccessPageTitle: "Talep Başarılı",
    reservationSuccessPageDescription:
      "Rezervasyon Numaranız: <strong class='text-black font-extrabold'>{id}</strong>",
    dear: "Sayın",
    reservationSuccessPageSuccessMessage: "Talebiniz başarıyla alınmıştır.",
    reservationSucessPageMailSendedMessage:
      "<strong class='text-black font-bold'>{mail}</strong> adresine bilgilendirme maili gönderilmiştir.;",
    error: "Hata!",
    SearchResultTitle: "Villanı Aramaya Başla",
    SearchResultFilterSummeryText: "{totalRecord} Villa Listelendi",
    SearchResultFilterOrderPlaceHolder: "Sıralama",
    orderOptions: [
      {
        value: "0",
        label: "Akıllı Sıralama",
      },
      // {
      //   value: "1",
      //   label: "Tarihe Göre (Önce En Eski)",
      // },
      // {
      //   value: "2",
      //   label: "Tarihe Göre (Önce En Yeni)",
      // },
      {
        value: "3",
        label: "Fiyata Göre (Önce En Düşük)",
      },
      {
        value: "4",
        label: "Fiyata Göre (Önce En Yüksek)",
      },
      {
        value: "5",
        label: "Kişiye Göre (Önce En Düşük)",
      },
      {
        value: "6",
        label: "Kişiye Göre (Önce En Yüksek)",
      },
    ],
    bankTransferFormTitle: "Havale Bildirim Formu",
    name: "Ad",
    surname: "Soyad",
    bankTransferFormAmount: "Gönderilen Miktar",
    bankTransferFormDate: "Gönderilen Tarih",
    bankTransferFormReceivingBank: "Alıcı Banka",
    bankTransferFormMessage: "Mesajınız",
    dateDay: "GG",
    dateMonth: "AA",
    dateYear: "YYYY",
    loginSuccess: "Giriş Başarılı",
    loadingText: "Yükleniyor",
    login: "Giriş Yap",
    bankTransferNotAuth:
      "Havale Bildirim Formu gönderebilmek için giriş yapmanız gerekiyor.",
    ProductCategoryItemSubText: "{count} villa içeriyor",
    loginLeftSubtitle: "Tekrar hoşgeldiniz,",
    loginLeftTitle: "Rezervasyon Takip",
    loginLeftCopyRight:
      "2021 @ Tüm Hakkı Saklıdır. İzinsiz İçerik Kullanılamaz.",
    welcome: "Hoşgeldiniz",
    loginRightDescription:
      "E-posta adresiniz ve Rezervasyon onay numaranız ile rezervasyonlarını bu bölümden takip yada kontrol edebilirsiniz. Rezervasyonunuz ile ilgili herhangi bir sorunla karşılaştığınız takdirde müşteri hizmetlerimizle acil olarak {contact}",
    loginRightDescriptionContactLink: "iletişime geçiniz.",
    loginFormEmail: "E-Posta Adresiniz",
    loginReservationNumber: "Rezervasyon Onay Kodu",
    loginRightDescriptionBottom:
      "Bu bölüme giriş yapabilmeniz için, daha önceden rezervasyon yapmış ve rezervasyonunuzun onaylanarak sizlere mail ve onay kodunun ulaşmış yada rezervasyonun kesinleşmiş olması gerekir. Sadece talep göndermiş müşterilerimiz bu bölüme ulaşamaz.",
    logout: "Çıkış Yap",
    reservationsTab1: "Aktif Rezervasyonlarınız",
    reservationsTab2: "Geçmiş Rezervasyonlarınız",
    reservationsTab1Error: "Aktif Rezervasyon Bulunamadı",
    reservationsTab2Error: "Geçmiş Rezervasyon Bulunamadı",
    ReservationStatus0: "Onay Bekliyor",
    ReservationStatus1: "Ödeme Bekliyor",
    ReservationStatus1ActionBtn: "Ödeme Bildirimi Yap",
    ReservationStatus1ActionCompleted: "Ödeme Bildirimi Yapıldı",
    ReservationStatus1ActionBtn2: "Kredi Kartı ile Öde",
    ReservationStatus3: "Onaylandı",
    ReservationStatus3Text: "Ödendi",
    ReservationStatus3ActionBtn: "Ödeme Bildirimi Yap",
    ReservationStatus3ActionBtn2: "Lütfen Misafir bilgilerini giriniz",
    ReservationStatus3ActionBtn2Completed: "Kişi Bilgileri Girildi",
    ReservationStatus4: "İptal Edildi",
    reservationNo: "Rez. No",
    reservationDate: "İşlem Tarih / Saat",
    reservationCustomerName: "Müşteri Ad Soyad",
    reservationDate1: "Giriş Tarihi",
    reservationDate2: "Çıkış Tarihi",
    reservationtionPrice: "Ödeme Tutarı",
    reservationGuestInformationTitle:
      "{reservationId}’nolu Rezervasyonunuz için {name} bilgilerinizi giriniz",
    reservationGuestInformationTitleName1: "Misafir",
    reservationGuestInformationTitleName2: "Fatura",
    reservationGuestInformationSubTitle:
      "En fazla {count} Kişi konaklayabilirsiniz.",
    GuestInformationTitle: "Misafir {no} bilgileri",
    GuestInformationName: "Ad Soyad",
    GuestInformationGender: "Cinsiyet",
    GuestInformationGenderMale: "Erkek",
    GuestInformationGenderFemale: "Kadın",
    GuestInformationBirthDate: "Doğum Tarihi",
    GuestInformationBirthPlace: "Doğum Yeri",
    GuestInformationPassaport: "Pasaport No",
    GuestInformationTc: "Tc",
    GuestInformationNotTurkeyPeople: "Tc Vatandaşı Değilim",
    goBack: "Geri Dön",
    save: "Kaydet",
    GuestInformationInvoiceType: "Fatura Tipi",
    GuestInformationInvoiceTypeOption1: "Bireysel",
    GuestInformationInvoiceTypeOption2: "Kurumsal",
    GuestInformationInvoiceTypeOption1Name: "Ad Soyad",
    GuestInformationInvoiceTypeOption2Name: "Şirket Adı",
    GuestInformationInvoiceTaxOffice: "Vergi Dairesi",
    GuestInformationInvoiceTaxNumber: "Vergi Numarası",
    address: "Adres",
    GuestInformationSuccessfullyTitle: "Misafir Bilgileri Başarıyla Alındı",
    GuestInformationSuccessfullyDescription:
      "Misafir bilgilerinizin özeti size e-posta ile gönderildi. Eğer bir hata olduğunu düşünüyorsanız lütfen Müşteri Hizmetlerimizle iletişime geçin.",
    PayByCreditCardTitle: "{price} ödemeyi",
    PayByCreditCardCaption: "Banka veya Kredi Kartı ile Öde",
    PayByCreditCardFormName: "Ad Soyad",
    PayByCreditCardFormCardNumber: "Kart üzerindeki 12 haneli numara",
    PayByCreditCardFormSubmit: "Öde",
    PayByCreditCardRightTitle: "{night} Gece Konaklama",
    PaymentAuthRequired: "Ödeme yapmak için giriş yapmanız gerekiyor.",
    installementFull: "Tek Çekim",
    installement: "Taksit",
    paymentSuccess: "Ödeme Başarılı",
    paymentSuccessDescription: "Bu pencereyi kapatabilirsiniz.",
    paymentTryAgain: "Lütfen Tekrar Deneyiniz",
    getOfferStep1Title1: "Villa bulma sihirbazına",
    getOfferStep1Title2: "Hoşgeldiniz",
    getOfferStep1Caption1: "Aradığın villayı",
    getOfferStep1Caption2: "bulmakta zorlanıyor musun ?",
    getOfferStep1SubTitle: "Sadece 5 adımda aradığın villayı kolayca bul",
    getOfferNextBtn: "Sonraki",
    getOfferPrevBtn: "Önceki",
    getOfferSendBtn: "Formu Gönder",
    getOfferStep2Title: "NASIL BİR VİLLADA TATİL YAPMAK İSTERSİNİZ",
    getOfferStep2Option1: "EKONOMİK",
    getOfferStep2Option2: "ORTA",
    getOfferStep2Option3: "LÜKS",
    getOfferStep3Title: "TATİLİNİZİ NE İÇİN PLANLIYORSUNUZ ?",
    getOfferStep3Option1: "Balayı veya Özel Gün",
    getOfferStep3Option2: "Aile veya Arkadaşlarla",
    getOfferStep4Title: "VİLLANIZDA OLMASINI İSTEDİĞİNİZ ÖZELLİKLER",
    getOfferStep5Title: "BİLGİLERİNİZ",
    getOfferFormFullName: "Ad Soyad",
    getOfferFormEmail: "Email Adresiniz",
    getOfferFormPhone: "Telefon Numaramız",
    getOfferFormPerson: "Kişi Sayısı",
    getOfferFormError: "Bir sorun oluştu",
    getOfferModalTitle: "TEŞEKKÜRLER",
    getOfferModalCaption:
      "Villa bulma sihirbazı size en uygun olan seçenekleri bulmak için çalışmaya başladı",
    getOfferModalDescription:
      "En kısa zamanda sizin için seçilen villalar sms ve email olarak gönderilecektir",
    blogSidebarSearchTitle: "Arama Yapın",
    blogSidebarSearchPlaceHolder: "Blog Ara",
    blogSidebarList1Title: "Son Yazılar",
    blogSidebarList2Title: "Popüler Yazılar",
    blogItemReadBtn: "Devamını Oku",
    bankItemBankName: "Banka Adı",
    bankItemBankBranch: "Şube Adı-Kodu",
    bankItemBankAccountName: "Hesap Adı",
    bankItemBankIban: "İban No",
    checkinInformationTitle: "Rezervasyon detaylarınız",
    viewInvoice: "Fatura Bilgilerini Gör",
    customerInformations: "Müşteri Bilgileri",
    villaManager: "Villa Sorumlusu/Görevlisi",
    callNow: "Hemen Ara",
    sendMessageViaWp: "Whatsapp’dan yaz",
    earliest: "En Erken",
    latest: "En Geç",
    paymentInformations: "Ödeme Bilgileri",
    remaining: "Kalan",
    accommodationPrice: "Konaklama Tutarı",
    paymentCheckin: "Girişte yapılması gereken ödeme",
    creditcardNotAccepted:
      "(Kredi kartı geçerli <span class='underline'>değildir</span>)",
    entranceInformationDamageDeposit:
      "Villaya girişte ödenecek tutardır. Ayrıca {hasar} değerinde hasar depozitosu alınacaktır.",
    entranceInformationLocationErrorOwner:
      "Lütfen Misafiriniz ile irtibata geçip konum bilgisi iletiniz!",
    entranceInformationLocationErrorCustomer:
      "Lütfen Villa Sorumlusu/Görevlisi ile irtibata geçip konum bilgisi isteyiniz !",
    UnconditionalmoneyTitle: "24 Saat İçerisinde",
    UnconditionalmoneySubTitle: "Koşulsuz Para İadesi",
    UnconditionalmoneyDescription:
      "*Rezervasyonun onaylanmasını takip eden 24 saat içerisinde İPTAL gerçekleşirse ziyaretçi ödemiş olduğu kaporayı koşulsuz iade alır.",
    UnconditionalmoneyDescription2:
      "Giriş tarihi en erken 21 gün içerisindeki olan rezervasyonlarda geçerli değildir.",
    discount: "İndirim",
    discountedAccommodationAmount: "İndirimli Konaklama Tutarı",
    appliedDiscount: "{code} kodu uygulandı",
    ihaveapromotioncode: "Promosyon Kodum Var",
    promotionCode: "Promosyon Kodu",
    apply: "Uygula",
    callCenterName: "Müşteri Hizmetleri",
    disableCalendarRules: "Takvim Kurallarına Uyma",
  },
  en: {
    datePickerlang: "en-US",
    headerLeft: [
      {
        url: "/villa-for-rent",
        title: "Villa for Rent",
        text: "Villa for Rent",
      },
      {
        url: "/2024-villas",
        title: "2024 Villas",
        text: "2024 Villas",
      },
      {
        url: "/regions",
        title: "Rental Regions",
        text: "Regions",
      },
    ],
    reservations: {
      url: "/my-reservations",
      title: "My Reservations",
      ariaLabel: "My Reservations",
    },
    blogPage: {
      url: "blog-discover",
    },
    searchResultSlug: "search-result",
    menu: "Menu",
    menuUpper: "MENU",
    close: "Close",
    closeUpper: "CLOSE",
    favourites: "Favourites",
    searchPlaceholder: "Search Villa",
    noResult: "No Result",
    searchMinLength: "Enter minimum 3 characters to search",
    searchBoxTitle: "Pay the remaining part of your vacation later!",
    forYou: "For You",
    mobileSliderCaption1: "Villas with Pool",
    mobileSliderCaption2: "Honeymoon Villas",
    mobileSliderCaption3: "Conservative Villas",
    destination: "Destination",
    villaOptions: "Villa Options",
    checkInOutDate: "Dates",
    customer: "Guests",
    searchUpper: "SEARCH",
    select: "Select",
    selected: "selected",
    adult: "Adult",
    child: "Child",
    informationBanner1Title: "100% Money Back Assurance",
    informationBanner1SubTitle: "& Free Cancellation",
    informationBanner1ButtonText: "View Details",
    documentNo: "Document No.",
    informationBanner2Title: "Villa Villam is a member travel agency of TÜRSAB",
    informationBanner2ButtonText: "Click for verification",
    informationBanner3Title: "Up to 12 monthly installments by credit card",
    informationBanner3ButtonText: "View Details",
    productDestinationsContainerTitle: "Rental Regions",
    productDestinationsContainerCaption: "Rental areas we serve",
    productCategoriesTitle: "Browse villa types",
    all: "All",
    showAll: "View All",
    allRentalVillas: "All Rental Villas",
    nightly: "Night",
    viewDetailUpper: "VIEW DETAILS",
    nightUpper: "NIGHT",
    person: "Person",
    bedRoom: "Bedroom",
    bathRoom: "Bathroom",
    villaItemPrice: "Cheapest",
    villaItemPriceNightly: "nightly",
    lastMinuteTitle: "Last Minute {Deals}",
    homeReviewLeftTitleSmall: "Do you need help?",
    homeReviewLeftTitle: "Villa Finding Wizard",
    homeReviewLeftText:
      "Having trouble choosing? Fill out the form and we will list and present the most suitable houses for you.",
    reviews: "Guest Comments",
    allReviews: "All Comments",
    shortTermsTitle: "Short Terms Rental Villas",
    nights: "Nights",
    faqVideoTitle: "How do I rent?",
    faqVideoText: "WATCH VIDEO",
    footerBannerTitle: "{Start} your vacation in a villa with a private pool.",
    footerBannerCaption:
      "Easily rent the villa you want from Turkey's holiday gate to happiness!",
    footerPaymentLinkButtonText:
      "Our Website is Protected with a 256 Bit SSL <br /> Certificate for Credit Card <br class='lg:hidden' /> Purchases",
    pay: "PAY NOW",
    copyRight:
      "2023 @ All Rights Reserved. Content Cannot Be Used Without Permission.",
    wpSupport: "WhatsApp Support",
    wpMessage: "I want to get information about {Villa}.",
    calendarUnavailable: "Unavailable",
    calendarOption: "Option",
    calendarLastMinute: "Last Minute Deal",
    datepickerCheckIn: "Check-in",
    datepickerCheckOut: "Check-out Date",
    warning: "Warning!",
    reservationUnavailableMessage:
      "The villa you are viewing is not available for reservations. If you wish, you can check out our other villas.",
    childAge: "6-12 Age",
    infant: "Infant",
    infantAge: "0-5 Age",
    back: "Back",
    reservationSubmitButtonText: "SEND A FREE BOOKING REQUEST",
    nameSurname: "Name & Surname",
    email: "Email",
    phone: "Phone",
    sendBtnText: "Send",
    reservationSummary: "Reservation Summary",
    accommodationAmount: "Accommodation Amount ({night} nights) ",
    remainingAmount: "Remaining (to be paid upon arrival)",
    cleaningFee: "Cleaning Fee",
    totalPrice: "Total Price",
    depositPrice: "Deposit",
    watchVideo: "Watch Video",
    showPhotos: "Show Photos",
    favourite: "Like",
    share: "Share",
    shareUpper: "SHARE",
    morePhotos: "More Photos",
    availabilityCalendar: "Availability Calendar",
    sidebarReservationTitle: "Reservation",
    sidebarReservationSubtitle:
      "<span class='text-orange'>{count} people</span> watching now",
    addFavourite: "Like",
    removeFavourite: "Unlike",
    shareViaWhatsapp: "Share via Whatsapp",
    shareViaFacebook: "Share via Whatsapp Facebook",
    shareViaTwitter: "Share via Whatsapp Twitter",
    shareViaMail: "Share via Whatsapp Mail",
    copyLink: "Copy Link",
    propertiesTitle: "Highlights",
    pricesTitle: "Prices",
    inludedPrice: "What's Included in the Price",
    notIncludedPrice: "What's Not Included in the Price",
    information: "Information",
    cancellationTerms: "Cancellation Terms",
    damageDeposit: "Damage Deposit",
    checkinTime: "Check-in Time (Earliest)",
    checkoutTime: "Check-out Time (Latest)",
    discover: "Discover",
    poolDimensions: "Pool Dimensions",
    childPool: "Child Pool",
    indoorPool: "Indoor Pool",
    distancesTitle: "Transportation Information",
    showOnMap: "Show on Map",
    locationInformation: "Location information",
    goToLocation: "Go to location",
    detailSimilarVillasTitle: "Browse Similar Villas",
    favouritesRightTitle: "Favourites",
    favouritesRightNotFound: "No villas added to favorites were found.",
    FavouriteItemDetailBtnText: "View",
    FavouriteItemRemoveBtnText: "Remove",
    MenubarHomeText: "Home",
    MenubarSearchText: "Search",
    MenubarButtonFilterText: "Filter",
    MenubarButtonReservationText: "Booking",
    AllVillas: "All Villas",
    notDates: "Please specify your Entry/Exit dates.",
    reservationTitle:
      "You are making a reservation for <strong>{baslik}</strong>",
    reservationSection1Title: "Enter Your Personal Information",
    reservationFormNamePlaceholder: "Name Surname",
    reservationFormEmailPlaceholder: "Email",
    reservationFormPhonePlaceholder: "Phone",
    reservationFormPhonePlaceholder2: "Phone",
    reservationSection2Title: "Choose Your Payment Method After Approval",
    reservationPaymentAll: "All",
    paymentMethod1Title: "Pay by Bank Transfer",
    paymentMethod1Description:
      "I want to make the payment amount by Money Order or EFT.",
    paymentMethod2Title: "Pay by Credit Card",
    paymentMethod2Description:
      "You can make your payment by credit card in one payment or in installments up to 12 months.",
    reservationSection3Title: "Things to consider when entering the villa",
    reservationDamageDeposit: "Damage Deposit",
    reservationDamageDepositDescription:
      "If there is no damage to the villa, it will be refunded upon departure.",
    reservationCheckinCheckoutTime: "Check-in and Check-out Times",
    reservationCheckinTime: "Check-in Time ",
    reservationCheckoutTime: "Check-out Time ",
    contractText: "I have read and accept the {contractName}",
    contract1: "Rental Agreement",
    contract2: "KVKK Clarification Text",
    reservationSendValidationError: "Please fill in all the required fields.",
    reservationSendBtnText: "SEND RESERVATION REQUEST",
    reservationSuccessPageTitle: "Request Successful",
    reservationSuccessPageDescription:
      "Reservation Number: <strong class='text-black font-extrabold'>{id}</strong>",
    dear: "Dear",
    reservationSuccessPageSuccessMessage:
      "Your request has been received successfully.",
    reservationSucessPageMailSendedMessage:
      "An informative e-mail was sent to  <strong class='text-black font-bold'>{mail}</strong>",
    error: "Error!",
    SearchResultTitle: "Start Searching for Your Villa",
    SearchResultFilterSummeryText: "{totalRecord} Villas Listed",
    SearchResultFilterOrderPlaceHolder: "Sort",
    orderOptions: [
      {
        value: "0",
        label: "Smart Sorting",
      },
      // {
      //   value: "1",
      //   label: "By Date (Oldest First)",
      // },
      // {
      //   value: "2",
      //   label: "By Date (Newest First)",
      // },
      {
        value: "3",
        label: "By Price (Lowest First)",
      },
      {
        value: "4",
        label: "By Price (Highest First)",
      },
      {
        value: "5",
        label: "By Person (Lowest First)",
      },
      {
        value: "6",
        label: "By Person (Highest First)",
      },
    ],
    bankTransferFormTitle: "Transfer Notification Form",
    name: "Name",
    surname: "Surname",
    bankTransferFormAmount: "Amount Sent",
    bankTransferFormDate: "Sent Date",
    bankTransferFormReceivingBank: "Receiving Bank",
    bankTransferFormMessage: "Message",
    dateDay: "DD",
    dateMonth: "MM",
    dateYear: "YYYY",
    loginSuccess: "Login Success",
    loadingText: "Loading",
    login: "Login",
    bankTransferNotAuth:
      "You need to log in to send the Bank Transfer Notification Form.",
    ProductCategoryItemSubText: "{count} villas",
    loginLeftSubtitle: "Welcome back,",
    loginLeftTitle: "Reservation Tracking",
    loginLeftCopyRight:
      "2021 © All Rights Reserved. Unauthorized Content May Not Be Used.",
    welcome: "Welcome",
    loginRightDescription:
      "You can track or check your reservations from this section with your email address and reservation confirmation number. If you encounter any problems with your reservation, please contact our customer service immediately {contact}",
    loginRightDescriptionContactLink: "contact us.",
    loginFormEmail: "Your Email Address",
    loginReservationNumber: "Reservation Confirmation Code",
    loginRightDescriptionBottom:
      "To access this section, you must have previously made a reservation and received the email and confirmation code confirming your reservation. Customers who have only submitted a request cannot access this section.",
    logout: "Log Out",
    reservationsTab1: "Your Active Reservations",
    reservationsTab2: "Your Past Reservations",
    reservationsTab1Error: "No Active Reservations Found",
    reservationsTab2Error: "No Past Reservations Found",
    ReservationStatus0: "Pending Approval",
    ReservationStatus1: "Awaiting Payment",
    ReservationStatus1ActionBtn: "Submit Payment Notification",
    ReservationStatus1ActionCompleted: "Payment Notification Submitted",
    ReservationStatus1ActionBtn2: "Pay By Credit Card",
    ReservationStatus3: "Approved",
    ReservationStatus3Text: "Paid",
    ReservationStatus3ActionBtn: "Submit Payment Notification",
    ReservationStatus3ActionBtn2: "Please Enter Guest Information",
    ReservationStatus3ActionBtn2Completed: "Guest Information Entered",
    ReservationStatus4: "Cancelled",
    reservationNo: "Res. No",
    reservationDate: "Transaction Date / Time",
    reservationCustomerName: "Customer Name Surname",
    reservationDate1: "Check-In Date",
    reservationDate2: "Check-Out Date",
    reservationtionPrice: "Payment Amount",
    reservationGuestInformationTitle:
      "Please enter {name} information for Reservation number {reservationId}",
    reservationGuestInformationTitleName1: "Guest",
    reservationGuestInformationTitleName2: "Invoice details",
    reservationGuestInformationSubTitle:
      "You can accommodate up to {count} people.",
    GuestInformationTitle: "Guest Information {no}",
    GuestInformationName: "Full Name",
    GuestInformationGender: "Gender",
    GuestInformationGenderMale: "Male",
    GuestInformationGenderFemale: "Female",
    GuestInformationBirthDate: "Date of Birth",
    GuestInformationBirthPlace: "Place of Birth",
    GuestInformationPassaport: "Passport Number",
    GuestInformationTc: "TC Number",
    GuestInformationNotTurkeyPeople: "I'm not a Turkish citizen",
    goBack: "Go Back",
    save: "Save",
    GuestInformationInvoiceType: "Invoice Type",
    GuestInformationInvoiceTypeOption1: "Individual",
    GuestInformationInvoiceTypeOption2: "Corporate",
    GuestInformationInvoiceTypeOption1Name: "Full Name",
    GuestInformationInvoiceTypeOption2Name: "Company Name",
    GuestInformationInvoiceTaxOffice: "Tax Office",
    GuestInformationInvoiceTaxNumber: "Tax Number",
    address: "Address",
    GuestInformationSuccessfullyTitle:
      " Guest Information successfully received.",
    GuestInformationSuccessfullyDescription:
      "A summary of guest information has been sent to you by email. If you believe there is an error, please contact our Customer Service.",
    PayByCreditCardTitle: "Pay {price}",
    PayByCreditCardCaption: "Pay with Bank or Credit Card",
    PayByCreditCardFormName: "Full Name",
    PayByCreditCardFormCardNumber: "12-digit number on the card",
    PayByCreditCardFormSubmit: "Pay",
    PayByCreditCardRightTitle: "{night} Night Accommodation",
    PaymentAuthRequired: "You need to sign in to make a payment.",
    installementFull: "Single Payment",
    installement: "Installment",
    paymentSuccess: "Ödeme Başarılı",
    paymentSuccessDescription: "Bu pencereyi kapatabilirsiniz.",
    paymentTryAgain: "Lütfen Tekrar Deneyiniz",
    getOfferStep1Title1: "Welcome to the",
    getOfferStep1Title2: "Villa finder wizard.",
    getOfferStep1Caption1: "Are you having difficulty finding",
    getOfferStep1Caption2: "the villa you are looking for?",
    getOfferStep1SubTitle:
      "Easily find the villa you're looking for in just 5 steps.",
    getOfferNextBtn: "Next",
    getOfferPrevBtn: "Prev",
    getOfferSendBtn: "Send Form",
    getOfferStep2Title: "WHAT KIND OF VILLA HOLIDAY ARE YOU LOOKING FOR",
    getOfferStep2Option1: "ECONOMICAL",
    getOfferStep2Option2: "MID-RANGE",
    getOfferStep2Option3: "LUXURY",
    getOfferStep3Title: "WHAT ARE YOU PLANNING YOUR HOLIDAY FOR?",
    getOfferStep3Option1: "Honeymoon or Special Occasion",
    getOfferStep3Option2: "With Family or Friends",
    getOfferStep4Title: "FEATURES YOU WANT IN YOUR VILLA",
    getOfferStep5Title: "YOUR INFORMATION",
    getOfferFormFullName: "Full Name",
    getOfferFormEmail: "Your Email Address",
    getOfferFormPhone: "Your Phone Number",
    getOfferFormPerson: "Number of People",
    getOfferFormError: "An error occurred",
    getOfferModalTitle: "THANK YOU",
    getOfferModalCaption:
      "The villa finder wizard has started working to find the most suitable options for you.",
    getOfferModalDescription:
      "The selected villas will be sent to you via SMS and email as soon as possible.",
    blogSidebarSearchTitle: "Search",
    blogSidebarSearchPlaceHolder: "Search Blog",
    blogSidebarList1Title: "Latest Posts",
    blogSidebarList2Title: "Popular Posts",
    blogItemReadBtn: "Read More",
    bankItemBankName: "Bank Name",
    bankItemBankBranch: "Branch Name-Code",
    bankItemBankAccountName: "Account Name",
    bankItemBankIban: "IBAN Number",
    checkinInformationTitle: "Reservation Details",
    viewInvoice: "View Invoice Details",
    customerInformations: "Customer Information",
    villaManager: "Villa Manager/Attendant",
    callNow: "Call Now",
    sendMessageViaWp: "Message via WhatsApp",
    earliest: "Earliest",
    latest: "Latest",
    paymentInformations: "Payment Information",
    remaining: "Remaining",
    accommodationPrice: "Accommodation Price",
    paymentCheckin: "Payment to be Made at Check-in",
    creditcardNotAccepted:
      "(Credit card <span class='underline'>not accepted</span>)",
    entranceInformationDamageDeposit:
      "The amount to be paid upon entering the villa. In addition, a damage deposit of {damage} will be taken.",
    entranceInformationLocationErrorOwner:
      "Please contact your guest and provide the location information!",
    entranceInformationLocationErrorCustomer:
      "Please contact the Villa Manager/Attendant and request location information!",
    UnconditionalmoneyTitle: "Within 24 Hours",
    UnconditionalmoneySubTitle: "Unconditional Money Back",
    UnconditionalmoneyDescription:
      "*If CANCELLATION occurs within 24 hours following the confirmation of the reservation, the visitor receives a refund of the deposit paid unconditionally.",
    UnconditionalmoneyDescription2:
      "Not valid for reservations with a check-in date within the earliest 21 days.",
    discount: "Discount",
    discountedAccommodationAmount: "Discounted Accommodation Amount",
    appliedDiscount: "{code} code applied",
    ihaveapromotioncode: "I Have a Promotion Code",
    promotionCode: "Promotion Code",
    apply: "Apply",
    callCenterName: "Call Center",
    disableCalendarRules: "Disable Calendar Rules",
  },
};

export const language = languages[process.env.NEXT_PUBLIC_LANGUAGE];
